import React from 'react'

const Pagination = ({ postsPerPage, length, handlePagination, currentPage, sibling }) => {
    const paginationNumber = [];
    const startPage = Math.max(currentPage - sibling, 1);
    const endPage = Math.min(currentPage + sibling, Math.ceil(length / postsPerPage));

    for (let i = startPage; i <= endPage; i++) {
        paginationNumber.push(i);
    }

    const onNext = () => {
        handlePagination(currentPage + 1);
    };

    const onPrevious = () => {
        handlePagination(currentPage - 1);
    };
    return (
        <div className="pagination">
            <button onClick={onPrevious} disabled={currentPage === 1} className="rotate-180">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="currentColor" className="w-[25px] h-[25px]">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"/>
                </svg>
            </button>
            {paginationNumber.map((data) => (
                <button
                    key={data}
                    onClick={() => handlePagination(data)}
                    className={currentPage === data ? 'active' : ''}
                >
                    {data}
                </button>
            ))}
            {paginationNumber.length === 0 && <button
                key="empty"
                className={'active'}
            >
                1
            </button>}
            <button onClick={onNext} disabled={currentPage === Math.ceil(length / postsPerPage)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="currentColor" className="w-[25px] h-[25px]">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"/>
                </svg>
            </button>
        </div>
    );
};
export default Pagination