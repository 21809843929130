import React, {useState} from 'react';
import {useTranslation} from "react-i18next";

export default function Whatis() {
    const steps = [
        {
            number: "01",
            header_tr: "Seyahatini planlamak için saaatlerini mi harcıyorsun? TFtrip sayesinde planın hazır!",
            header_en: "Are you spending hours planning your trip? Thanks to TFtrip, your plan is ready!",
            description_tr: "Saatlerce farklı kaynaklardan seyahat noktalarını planlamak yerine,takip ettiğin ve ait olduğun topluluğun güvenilir influencer'larının rotalarından yararlan. TFtrip sayesinde seyahat planın hazır! Gezilecek yerlerden, denenecek lezzetlere kadar tüm soruların yanıtları TFtrip'te.",
            description_en: "Instead of spending hours planning travel destinations from different sources, take advantage of the routes of trusted influencers of the community you follow and belong to. Thanks to TFtrip, your travel plan is ready! Answers to all your questions from places to visit to flavors to try are on TFtrip.",
            image: "/img/app/mockup1.png"
        },
        {
            number: "02",
            header_tr: "Farklı perspektiften seyahat deneyimi!",
            header_en: "Travel experience from a different perspective!",
            description_tr: "Sevilen, tavsiyelerine kıymet verilen influencerlar TFtrip uygulaması ile takip edilerek, bu kişilerin paylaştıkları tur ve gezi noktalarını bir rota halinde görülebilir. Kendini ait hissettiğin kitlenin deneyimleri, tecrübeleri bu rotalarda seni bekliyor...",
            description_en: "By following the influencers whose advice is valued with the TFtrip application, you can see the tours and travel destinations shared by these people as a route. The experiences and experiences of the audience you feel you belong to are waiting for you on these routes...",
            image: "/img/app/mockup2.png"
        },
        {
            number: "03",
            header_tr: "Özel indirim ve avantajları yakala!",
            header_en: "Get special discounts and advantages!",
            description_tr: "TFtrip ile seyahat etmek sadece harika rotalara sahip olmakla kalmaz, aynı zamanda özel indirimler ve avantajlarla doludur. Unutulmaz bir gezi için özel fırsatları kaçırmayın!",
            description_en: "Traveling with TFtrip not only has great routes, but is also full of special discounts and benefits. Don't miss special deals for an unforgettable trip!",
            image: "/img/app/mockup3.png"
        },
        {
            number: "04",
            header_tr: "Şehrinde veya gideceğin şehirde influencer etkinliği varsa gör ve katılacağını bildir!",
            header_en: "See if there is an influencer event in your city or destination and let them know you will attend!",
            description_tr: "Seyahat planları yaparken veya şehrinde geçireceğin zamanı planlarken, sevdiğin influencerlardan birisi katılacağı etkinlikleri etkinlikler sekmesinden belirtirse, katılma kararını bildir ve diğer katılımcılarla buluşma yerini belirle",
            description_en: "When you're making travel plans or planning your time in your city, if one of your favorite influencers announces their upcoming events in the events tab, notify them of your decision to attend and set up a meeting place with other attendees",
            image: "/img/app/mockup4.png"
        }
    ]
    const {t,i18n} = useTranslation()
    return (
        <div className="flex space-y-24 mb-24 lg:mt-12 md:mt-4 w-full justify-center">
            <div className="flex flex-col gap-24 md:px-[48px] px-4 container w-fit">
                <div className="mt-12 space-y-[15px]">
                    <p className="font-bold text-[24px] leading-[24px] text-[#485c83]">{t("howtoheader")}</p>
                    <p className="font-bold text-[44px] leading-[53px] text-[#333]">{t("fourstep")}</p>
                </div>
                {steps.map((stepsDetails, index) => (
                    <div
                        key={index}
                        className={`flex ${index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'} justify-center flex-col-reverse lg:gap-0 gap-12`}>
                        <div className="md:w-[556px] w-full flex flex-col gap-4">
                            <div className="flex justify-between items-center">
                                <p className="text-[86px] leading-[86px] font-semibold text-[#ddd]">{stepsDetails.number}</p>
                                {/**<button className="md:hidden flex justify-center items-center font-normal text-[14px] uppercase w-[75px] h-[35px] rounded-full bg-orange-600 active:bg-orange-500 transition-all text-white mr-8">show</button>*/}
                            </div>
                            <p className="text-[30px] leading-[36px] font-bold text-[#333]">{i18n.language === "tr" ? stepsDetails.header_tr : i18n.language === "tr-TR" ? stepsDetails.header_tr : stepsDetails.header_en}</p>
                            <p className="text-[18px] leading-[32px] font-normal text-[#555]">{i18n.language === "tr" ? stepsDetails.description_tr : i18n.language === "tr-TR" ? stepsDetails.description_tr : stepsDetails.description_en}</p>
                        </div>
                        <div className="md:w-[556px] w-full flex justify-center items-center ">
                            <img src={stepsDetails.image} className="z-10 absolute h-[350px]" alt=""/>
                            <div className="bg-[#FDF2E9] w-[300px] z-0 h-[300px] rounded-full p-[40px]">
                                <div className="bg-[#FAE5D3] w-full h-full rounded-full"></div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

    )
}