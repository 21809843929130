import HeadComponent from "../components/homepage/HeadComponent";
import Partners from "../components/homepage/Partners";
import Whatis from "../components/homepage/Whatis";
import Supporters from "../components/homepage/Supporters";
import Reviews from "../components/homepage/Reviews";
import ForMore from "../components/homepage/forMore";
import Modal from "../components/modal/Modal";
import React from "react";

export default function HomePage(handleOpen) {
    return (
        <>
        <div className="overflow-x-hidden no-scrollbar">
            <Modal/>
            <HeadComponent/>
            <Partners/>
            <Whatis/>
            <Supporters/>
            <Reviews/>
        </div>
        <div className="lg:flex hidden sticky bottom-4 w-full z-50 justify-center bg-transparent">
            <ForMore/>
        </div>
        </>
)
    ;
}
