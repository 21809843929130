import React, {useEffect, useState} from "react";
import Post from "../components/blog/Post";
import Pagination from "../components/blog/Pagination";
import searchBar from "../components/blog/searchBar";

export default function Blog() {
    const [query, setQuery] = useState("");
    const [posts, setPosts] = useState([]);
    const [allPosts, setAllPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPge, SetPostsPerPage] = useState(10);

    useEffect(() => {
        const fetchPosts = async () => {
            setLoading(true);
            try {
                const response = await fetch("../datas/rotalar1.json");
                const data = await response.json();
                setPosts(data);
                setAllPosts(data);
                setLoading(false)
            } catch (error) {
                console.log(error);
            }
        }
        fetchPosts();
    }, [])
    const filterPosts = async () => {
        if (query === '') {
            // If query is empty, return all posts
            setPosts(allPosts);
        } else {
            // Filter posts based on query
            const filteredPosts = posts.filter(
                (data) =>
                    data.header.toLowerCase().includes(query.toLowerCase()) ||
                    data.description.toLowerCase().includes(query.toLowerCase()) ||
                    data.author.toLowerCase().includes(query.toLowerCase())
            );
            setPosts(filteredPosts);
        }
    };

    useEffect(() => {
        filterPosts();
    }, [query]);

    const indexOfLastPost = currentPage * postsPerPge;
    const indexOfFirstPost = indexOfLastPost - postsPerPge;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

    const handlePagination = async (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    return (
        <div className="w-full h-full flex justify-center py-4 md:px-0 px-4">
            <div className="container flex flex-col gap-4">
                <div className="flex justify-between items-center">
                    <p className="capitalize fjalla text-[60px] leading-[60px]">Blog</p>
                    <div className="search w-[50%] flex flex-row items-center gap-2 ">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="searchIcon w-6 h-6 flex absolute mx-2">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"/>
                        </svg>

                        <input placeholder="Search"
                               onChange={(event) => setQuery(event.target.value)}
                               value={query}
                               id="searchBar"
                               type="text"
                               className="search-box w-full border rounded-full ps-[40px] focus:border-1 focus:border-[#FE9A61] focus:ring-0 focus:outline-0 transition-all"/>
                    </div>
                </div>
                <div className=" gap-4 w-full h-full flex flex-col">
                    <Post posts={currentPosts} loading={loading} query={query}/>
                    <Pagination length={posts.length} postsPerPage={postsPerPge} handlePagination={handlePagination}
                                sibling={2} currentPage={currentPage}/>
                </div>
            </div>
        </div>

    )
}