import {Link} from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import {useTranslation} from "react-i18next";

function Footer() {
    const {t} = useTranslation()
    return (
        <footer className="bg-white dark:bg-gray-900 flex justify-center lg:px-[48px] px-4">
            <div className="container py-8 lg:py-0 lg:pb-8">
                <div className="md:flex md:justify-between">
                    <div className="mb-6 md:mb-0">
                        <Link to="https://flowbite.com/" className="flex items-center">
                            <img src="/img/tftrip_logomuz.png" className="h-8 me-3"
                                 alt="TfTrip Logo"/>
                            <span
                                className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">" The Free Trip "</span>
                        </Link>

                        <Link to="https://onelink.to/pu8x4w"  // Pass the function here
                              className="flex gap-2 justify-between items-center md:w-fit  mt-6 py-2 px-8 rounded-2xl text-white bg-[#495C83] text-[18px] hover:bg-[#5c739b] transition-all">
                            <button>{t("download")}</button>
                            <DownloadIcon/>
                        </Link>
                    </div>
                    <div className="grid grid-cols-2 gap-8 sm:gap-6">
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">{t("resources")}</h2>
                            <ul className="text-gray-500 dark:text-gray-400 font-medium">
                                <li className="mb-4">
                                    <Link to="/" className="hover:underline" target="_top">{t("homepage")}</Link>
                                </li>
                                <li className="mb-4">
                                    <Link to="https://thefreetrip.com/rotalar.html" target="_top"
                                          className="hover:underline">Blog</Link>
                                </li>
                                <li>
                                    <Link to="/kvkk" target="_top" className="hover:underline">{t("kvkk")}</Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">{t("contact")}</h2>
                            <ul className="text-gray-500 dark:text-gray-400 font-medium">
                                <li className="mb-4">
                                    <Link to="tel:+90 (545) 279 78 50" className="hover:underline">+90 (545) 279 78
                                        50</Link>
                                </li>
                                <li className="mb-4">
                                    <Link to="mailto:info@thefreetrip.com"
                                          className="hover:underline ">info@thefreetrip.com</Link>
                                </li>
                                <li>
                                    <p className="hover:underline">{t("location")}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"/>
                <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <Link
              to="/" className="hover:underline">Tftrip™</Link>. {t("rights")}.
          </span>
                    <div className="flex gap-5 mt-4 sm:justify-center sm:items-center sm:mt-0">
                        <Link to="https://www.instagram.com/tftripapp/"
                              className="social text-gray-500 hover:text-gray-900 dark:hover:text-white">
                            <ion-icon name="logo-instagram"></ion-icon>
                        </Link>
                        <Link to="https://www.tiktok.com/@tftripapp"
                              className="social text-gray-500 hover:text-gray-900 dark:hover:text-white">
                            <ion-icon name="logo-tiktok"></ion-icon>
                        </Link>
                        <Link to="https://www.linkedin.com/company/tftrip/posts/?feedView=all" className="social text-gray-500 hover:text-gray-900 dark:hover:text-white">
                            <ion-icon name="logo-linkedin"></ion-icon>
                        </Link>
                        <Link to="https://twitter.com/thefreetrip" className="social text-gray-500 hover:text-gray-900 dark:hover:text-white">
                            <ion-icon name="logo-twitter"></ion-icon>
                        </Link>

                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer;