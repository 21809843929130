import * as React from 'react';
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Header() {
    const {t,i18n} = useTranslation()
    const clickHandle = async lang => {
        await i18n.changeLanguage(lang)
    }
    return (
        <div className="bg-white/30 backdrop-blur-lg  sticky top-0 z-50 h-[80px] text-[16px] md:px-[48px] px-4 font-semibold leading-[18px] flex justify-center">
            <div className="flex justify-between container h-full items-center">
                <Link to="/">
                    <img className="logo w-[70px]"
                         alt="TFtrip logo"
                         src="/img/tftrip_logomuz.png"/>
                </Link>
                <div className="flex gap-4">
                    <Link className="main-nav-link border-none hover:text-[#FE9A62] transition-all uppercase" to="/">{t("homepage")}</Link>
                    <p>|</p>
                    <Link className="main-nav-link border-none hover:text-[#FE9A62] transition-all uppercase"
                          to="/blog">Blog</Link>
                </div>

                <div className="flex">
                    <ul className="main-nav-list flex gap-2 ">
                        <li><Link className="main-nav-link" onClick={() => clickHandle('tr')}>TR</Link></li>
                        <p>|</p>
                        <li><Link className="main-nav-link" onClick={() => clickHandle('en')}>EN</Link></li>
                    </ul>
                </div>
            </div>
        </div>

    );
}
