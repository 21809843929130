import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Kvkk() {
    const {t} = useTranslation()
    return (
        <div className="px-12 mb-8 py-8 border-y">
            <p>
                <h1 className="font-bold text-[32px]">{t("kvkk1h")}</h1>
                <br/>
                {t("kvkk1desc")}
                <br/>
                <br/>

                <b>{t("kvkk2h")}</b>
                <br/>

                {t("kvkk2desc")}
                <br/>
                <br/>

                <b>{t("kvkk3h")}</b>
                <br/>
                {t("kvkk3desc")}
                <br/>
                <br/>

                <b>{t("kvkk4h")}</b>
                <br/>

                {t("kvkk4desc")}
                <br/>
                <br/>

                <b>{t("kvkk5h")}</b>
                <br/>

                {t("kvkk5descLH")}
                <br/>
                <br/>

                <li>{t("kvkk5descL1")}</li>
                <li>{t("kvkk5descL2")}</li>
                <li>{t("kvkk5descL3")}</li>
                <li>{t("kvkk5descL4")}</li>

                <br/>


                {t("kvkk5descLS")}
                <br/>
                <br/>

                <b>{t("kvkk6h")}</b>
                <br/>

                {t("kvkk6desc")}

                <br/>
                <br/>
                <b>{t("kvkk7h")}</b>
                <br/>

                {t("kvkk7desc")}
                <br/>
                <br/>

                <b>{t("kvkk8h")}</b>
                <br/>

                {t("kvkk8desc")}
                <br/>
                <br/>

                <b>{t("kvkk9h")}</b>
                <br/>

                {t("kvkk9desc")}
                <br/>
                <br/>

                <b>{t("kvkk10h")}</b>
                <br/>

                {t("kvkk10desc")} <Link className="text-blue-600/75 hover:text-blue-600 font-thin" to="mailto:info@thefreetrip.com"><b>{t("kvkk10link")}</b></Link>
            </p>
        </div>
    )
}