import React from 'react';
import {Link} from 'react-router-dom';

const Post = ({posts, loading}) => {
    if (loading) {
        return <h1>Loading.....</h1>;
    }
    return (

        <>
            {posts.length > 0 &&
            <div className={`gap-4 w-full h-full grid md:grid-cols-2 grid-cols-1`}>
                {posts.map((data, index) => (
                    <Link
                        className="list hover:shadow-lg rounded-lg overflow-hidden transition-all cursor-pointer shadow-md h-full flex flex-col"
                        key={index}
                        to={`${data.author}/${data.header}`}
                    >
                        <img
                            src={data.img}
                            className="flex w-full min-h-[220px] max-h-[220px] object-cover"
                            alt={data.header}
                            id={data.index}
                        />

                        <div className="p-8 gap-3 h-full w-full flex flex-col justify-between">
                            {data.createDate &&
                                <p className="font-normal text-[14px] leading-[20px] font-Franklin text-[#374151]">
                                    {data.createDate}
                                </p>
                            }
                            <h1 className="font-bold text-[24px] leading-[32px] font-Franklin text-black line-clamp-2">
                                {data.header}
                            </h1>
                            <p className="w-full line-clamp-3 text-[#4b5563] text-[16px] font-Franklin leading-[24px] font-normal">
                                {data.description}
                            </p>
                            <Link
                                to={`https://instagram.com/${data.author}/`}
                                className="flex justify-start items-center z-10 gap-2"
                            >
                                <img
                                    src="https://w7.pngwing.com/pngs/686/219/png-transparent-youtube-user-computer-icons-information-youtube-hand-silhouette-avatar.png"
                                    className="w-[50px] h-[50px] rounded-full drop-shadow-md"
                                    alt={data.author}
                                />
                                <div>
                                    {data.author.length !== 0 ?
                                        <p className="font-semibold capitalize text-[14px] leading-[20px] font-Franklin text-[#374151]">
                                            {data.author}
                                        </p> :
                                        <p className="font-semibold text-[14px] leading-[20px] font-Franklin text-[#374151]">
                                            TFtrip Yazarı
                                        </p>
                                        }

                                </div>
                            </Link>
                        </div>
                    </Link>
                ))}
            </div>
            }
            {posts.length === 0 &&
                <div className="w-full h-[calc(100vh-300px)] flex justify-center items-center">
                    Görüntülenecek içerik bulunamadı
                </div>
            }
        </>
    );
};

export default Post;