// App.js
import React from 'react';
import {Route, Routes} from 'react-router-dom';
import HomePage from './pages/HomePage'; // Ana sayfa bileşeni
import Kvkk from './pages/Kvkk';
import Header from "./components/header/Header";
import Footer from "./components/footer/footer";
import Blog from "./pages/Blog";
import BlogPost from "./pages/BlogPost";
import AuthorProfile from "./pages/AuthorProfile";

function App() {
    return (
        <>
            <Header/>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/kvkk" element={<Kvkk/>}/>
                <Route path="/blog" element={<Blog/>}/>
                <Route path="/blog/:author" element={<AuthorProfile/>}/>
                <Route path="/blog/:author/:slug" element={<BlogPost/>}/>
            </Routes>
            <Footer/>
        </>

    );
}

export default App;