import React, {useState} from 'react';
import {Modal} from 'antd';
import DownloadIcon from "@mui/icons-material/Download";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const App = () => {
    const {t,i18n} = useTranslation()
    const [open, setOpen] = useState(true);
    const handleCancel = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    return (
        <>
            <Modal
                open={open}
                title={t("modalHeader")}
                onCancel={handleCancel}
                footer={[
                ]}

            >
                <div className="flex justify-start items-start">
                    <img
                        src="https://media.licdn.com/dms/image/D4D22AQFh3TqMr_Vs2Q/feedshare-shrink_1280/0/1711393848855?e=1719446400&v=beta&t=NzAatnygyW4DU86x4ccyWngRO9PKXDJOkfzUddCAkco"
                        alt=""/>
                    <div
                        className="md:flex hidden w-fit h-fit justify-center tracking-widest text-white font-semibold  items-center gap-1 flex-col absolute p-4 ">
                        <img className="mix-blend-lighten border z-50 " src="/img/1233.png" alt=""/>
                        <p className="text-[16px]">{t("download")}</p>
                    </div>
                </div>
                <Link to="https://onelink.to/pu8x4w"
                      className="flex gap-2 justify-center items-center w-full mt-6 py-2 px-8 rounded-full text-white bg-gradient-to-l shadow-lg from-[#FF9A62] to-[#986FF3] text-[18px] hover:bg-[#5c739b] transition-all">
                    <button className="hover:text-white">{t("download")}</button>
                    <DownloadIcon/>
                </Link>
            </Modal>
        </>
    );
};
export default App;