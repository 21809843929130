import { useParams } from "react-router-dom";

function BlogPost() {
    const { slug } = useParams();

    return (
        <div>
            <h1>{slug}</h1>
            <p>This is the content of the Author Profile with slug {slug}.</p>
        </div>
    );
}

export default BlogPost;