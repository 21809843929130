import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import DownloadIcon from '@mui/icons-material/Download';
import {useTranslation} from "react-i18next";

export default function HeadComponent() {

    const {t} = useTranslation()
    const [showText1, setShowText1] = useState(true);
    const [showText2, setShowText2] = useState(false);

    useEffect(() => {
        const textTimeout = setTimeout(() => {
            setShowText1(!showText1);
            setShowText2(!showText2);
        }, 10000); // Her 15 saniyede bir metinleri değiştir

        // Temizlik: Zamanlayıcıyı temizle
        return () => {
            clearTimeout(textTimeout);
        };
    }, [showText1, showText2]);

    return (
        <div
            className="App bg-[#ffe6d4] w-full h-full md:px-[48px] px-4 md:py-[72px] py-4 flex justify-center md:items-start items-center">
            <div className="container">
                <div className="w-full flex justify-center">
                    <div className="justify-center flex md:flex-col lg:flex-row flex-col-reverse gap-12 w-fit">
                        <div className="w-full lg:w-fit md:justify-center md:flex hidden
                        justify-start items-start"> {/** Tablet */}
                            <div
                                className="flex absolute md:hidden w-[300px] z-10 md:p-0 p-4 font-bold text-[40px] leading-[50px] text-[#fff]">
                                {showText2 && (
                                    <typewritten-text>{t("dactiloText2")}
                                    </typewritten-text>
                                )}
                                {showText1 && (
                                    <typewritten-text>
                                        {t("dactiloText1")}
                                    </typewritten-text>
                                )}
                            </div>
                            <img src="/img/headComponent.jpg" alt=""
                                 className="md:max-w-[390px] w-auto md:brightness-100 brightness-50 opacity-95 rounded-lg "/>
                        </div>
                        <div className="w-fit h-full flex justify-center items-center"> {/** PC */}
                            <div className="flex-col h-fit md:space-y-[30px] space-y-[15px]">
                                <div
                                    className="deneme font-bold xl:text-[52px] lg:text-[46px] md:text-[40px] text-[34px] leading-[50px] flex-col md:space-y-5 text-[#333]">
                                    <p className="md:flex hidden h-fit">
                                        {showText2 && (
                                            <typewritten-text>{t("dactiloText2")}
                                            </typewritten-text>
                                        )}
                                        {showText1 && (
                                            <typewritten-text>
                                                {t("dactiloText1")}
                                            </typewritten-text>
                                        )}
                                    </p>
                                    <p className=" font-bold text-[#FF9A62] xl:text-[48px] lg:text-[44px] text-[40px] leading-[48px]">
                                        {t("headCompHeader")}
                                    </p>

                                </div>
                                <p className="font-medium text-[20px] leading-[32px] text-[#555]">
                                    {t("headCompDesc")}
                                </p>
                                <div className="flex w-full h-fit md:justify-start p-0 m-0 justify-center">
                                    <Link  to="https://onelink.to/pu8x4w"  // Pass the function here
                                          className="flex gap-2 justify-center items-center md:w-fit w-full md:my-0 my-3 py-2 px-8 rounded-2xl text-white bg-[#495C83] text-[18px] hover:bg-[#5c739b] transition-all">
                                        <button>{t("download")}</button>
                                        <DownloadIcon/>
                                    </Link>
                                </div>


                                <div className="w-full h-full flex justify-start">
                                    <div
                                        className="delivered-meals md:flex flex-col start-0 items-center space-y-[1rem]">
                                        <div className="delivered-imgs flex">
                                            <img src="/img/customer-1.jpg" alt="Customer-1"/>
                                            <img src="/img/customer-2.jpg" alt="Customer-2"/>
                                            <img src="/img/customer-3.jpg" alt="Customer-3"/>
                                            <img src="/img/customer-4.jpg" alt="Customer-4"/>
                                            <img src="/img/customer-5.jpg" alt="Customer-5"/>
                                            <img src="/img/customer-6.jpg" alt="Customer-6"/>

                                        </div>
                                        <p className="delivered-text w-[200px] leading-[18px] text-[16px] font-semibold text-[#555]">
                                            <span
                                                className="font-bold text-[#FE9A62] !text-[16px]">50+</span> {t("influencer")}
                                        </p>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div
                            className=" w-fit col-span-5 md:justify-center flex md:hidden justify-start items-center">  {/** Telefon */}
                            <div
                                className="flex absolute md:hidden w-[300px] z-10 md:p-0 p-4 font-bold text-[36px] leading-[50px] text-[#fff]">
                                {showText1 && (
                                    <typewritten-text>{t("dactiloText2")}
                                    </typewritten-text>
                                )}
                                {showText2 && (
                                    <typewritten-text>
                                        {t("dactiloText1")}
                                    </typewritten-text>
                                )}
                            </div>
                            <img src="/img/headComponent.jpg" alt=""
                                 className="md:max-w-[390px] w-auto md:brightness-100 brightness-75 opacity-95 rounded-lg "/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}