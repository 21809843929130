import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Supporters() {
    const supporters = [
        {
            company:"Despro",
            image: "/img/logos/despro_sponsorumuz.png",
            slug: "https://www.despro.com.tr/"
        },
        {
            company:"Art Brand Atelier",
            image: "/img/logos/art_sponsor.png",
            slug: "https://www.instagram.com/artbrandatelier/"
        },
        {
            company:"Karsiyaka Kolektif",
            image: "/img/logos/karsıyaka_kolektif_sponsor.png",
            slug: "https://karsiyakakolektif.com/"
        },
        {
            company:"Minerva Kulucka Merkezi",
            image: "/img/logos/minerva_kulucka_merkezi_sponsor.png",
            slug: "https://minerva.yasar.edu.tr/"
        },
        {
            company:"IBT",
            image: "/img/logos/ibt_sponsor.png",
            slug: "https://www.ibt.org.tr/"
        }
    ];
    const {t,i18n} = useTranslation()
    return (
        <div className=" bg-[#FCF7F2] h-fit">
            <div className="flex-col text-center w-full space-y-[32px] h-fit py-[32px]">
                <h1 className="capitalize p-0 m-0 text-[21px] font-medium leading-[21px] ">{t("supporters")}</h1>
                <div
                    className="flex snap-mandatory snap-x lg:justify-center justify-start overflow-x-auto no-scrollbar gap-[75px]">
                    {supporters.map((logos, index) => (
                        <Link to={logos.slug} target={"_blank"} key={index} className="snap-center">
                            <img className="lg:ml-0 md:ml-4 ml-8 object-contain min-w-[150px] h-[60px]"
                                 src={logos.image}
                                 alt={logos.company}/>
                        </Link>
                    ))}
                </div>

            </div>
        </div>
    )
}