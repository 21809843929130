import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {redirect} from "react-router";
import { Collapse } from "antd";

function BlogPost() {
    const {slug} = useParams();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchPosts = async () => {
            setLoading(true);
            try {
                const response = await fetch("/datas/rotalar1.json");
                const data = await response.json();
                setPosts(data);
                setLoading(false)
            } catch (error) {
                console.log(error);
            }
        }
        fetchPosts();
    }, [])
    console.log(slug)
    return (
        <div>
            {posts.length && posts.filter((data) => {
                if (
                    data.header.toLowerCase().includes(slug.toLowerCase()) ||
                    data.header.toUpperCase().includes(slug.toUpperCase())
                ) {
                    // Returns filtered array
                    return data;
                } else {
                    redirect("/blog")
                }
            }).map((data, index) => (
                <div className="w-full h-full scroll-smooth flex px-4 justify-center py-4">
                    <div className="container w-full scroll-smooth h-full flex-col space-y-4 justify-center">
                        <Link
                            className="w-fit flex justify-center items-center gap-2 text-[16px] text-[#0ea5e9] hover:text-[#0370a1] leading-[24px]"
                            to={"/blog"}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-[16px] h-[16px] rotate-180">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"/>
                            </svg>
                            Blog sayfasına dön
                        </Link>
                        <div className="flex flex-col gap-4">
                            <h1 className="font-Franklin font-extrabold text-[36px] leading-[40px]">{data.header}</h1>
                            <img src={data.img}
                                 className="w-[750px] h-[422px] object-cover overflow-hidden rounded-xl md:mb-10" alt=""/>
                            <div className="flex justify-start items-center gap-4 md:mb-8 mb-0">
                                <img
                                    src="https://w7.pngwing.com/pngs/686/219/png-transparent-youtube-user-computer-icons-information-youtube-hand-silhouette-avatar.png"
                                    className="w-[60px] h-[60px] rounded-full drop-shadow-md" alt=""/>
                                <div>
                                    <p className="font-bold text-[16px] leading-[24px] font-Franklin text-[#374151]">
                                        @{data.author}
                                    </p>
                                    {data.createDate &&
                                        <p className="font-normal text-[16px] leading-[24px] font-Franklin text-[#374151]">
                                            {data.createDate}
                                        </p>
                                    }
                                </div>
                            </div>
                            <p className="font-normal text-[16px] leading-[24px] font-Franklin text-[#374151] mb-10">{data.description}</p>
                            <h2 className="font-normal text-[24px] leading-[32px] font-Franklin text-[#374151]">{data.placesHeader}</h2>
                            <div className="flex flex-col gap-2 scroll-smooth px-6 mb-10 list-inside">
                                {data.places.map((place, index) => (
                                    <a className="list-item scroll-smooth" href={`#${place.header}`}>
                                        {place.header}
                                    </a>
                                ))}
                            </div>

                            <div className="list-none scroll-smooth  relative border-s border-gray-200 dark:border-gray-700">
                                {data.places.map((place, index) => (
                                    <li className="mb-10 scroll-smooth ms-6" id={place.header}>
                                    <span
                                        className="absolute flex items-center justify-center w-6 h-6 bg-[#d1d8e6] rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                                        <svg className="w-2.5 h-2.5 text-[#485c84] dark:text-blue-300" aria-hidden="true"
                                             xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path
                                                d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                                        </svg>
                                    </span>
                                        <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">{index+1}. {place.header}
                                        </h3>
                                        <p className="mb-16 text-base font-normal text-gray-500 dark:text-gray-400">{place.description}</p>
                                    </li>
                                ))}
                            </div>

                            <ol className="relative border-s border-gray-200 dark:border-gray-700">

                            </ol>

                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default BlogPost;