import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import React from "react";
import {useTranslation} from "react-i18next";


export default function ForMore() {
    const {t,i18n} = useTranslation()
    return (
    <div className="flex sticky bottom-4 w-full z-50 justify-center bg-transparent">
        <div
            className="flex select-none bg-[#FF9A62]/75 py-4 animate-bounce px-6 rounded-full backdrop-blur-lg text-white">
            <p>{t("formore")}</p>
            <span>
                <KeyboardDoubleArrowDownIcon/>
            </span>
        </div>
    </div>
    )
}