import React from 'react';
import {useTranslation} from "react-i18next";

export default function Reviews() {
    const images = [
        {image: "img/gallery/g1.jpeg",alt:"g1"},
        {image: "img/gallery/g2.jpeg",alt:"g2"},
        {image: "img/gallery/g3.jpeg",alt:"g3"},
        {image: "img/gallery/g4.jpeg",alt:"g4"},
        {image: "img/gallery/g5.jpeg",alt:"g5"},
        {image: "img/gallery/g6.jpeg",alt:"g6"},
        {image: "img/gallery/g7.jpeg",alt:"g7"},
        {image: "img/gallery/g8.jpeg",alt:"g8"},
        {image: "img/gallery/g9.jpeg",alt:"g9"},
        {image: "img/gallery/g10.jpeg",alt:"g10"},
        {image: "img/gallery/g11.jpeg",alt:"g11"},
        {image: "img/gallery/g12.jpeg",alt:"g12"}
    ]
    const reviews = [
        {
            image: "img/customers/berkeozkl.jpeg",
            customer: "Berke Özkul",
            comment_tr: "İster turist ister rehber olun, müthiş deneyimlerin paylaşıldığı bu uygulamayı takipte kalın.",
            comment_en: "Whether you are a tourist or a guide, stay tuned to this app where great experiences are shared."
        },
        {
            image: "img/customers/ayhan_zengin.jpg",
            customer: "Ayhan Zengin",
            comment_tr: "Artık seyahatimi planlamak için saatler harcamama gerek olmayacak, bu harika!",
            comment_en: "I won't have to spend hours planning my trip anymore, which is great!"
        },
        {
            image: "img/customers/orcıun_kabak.jpg",
            customer: "Orçun Kabak",
            comment_tr: "Seyahat esnasında yol tarifi alabilmek ve detay açıklamaları görebilmek harika olmuş.",
            comment_en: "It is great to be able to get directions and see detailed descriptions while traveling."
        },
        {
            image: "img/customers/pera_ozdemir.jpg",
            customer: "Pera Özdemir",
            comment_tr: "Uygulama sayesinde güvenle araç kiralama yapabildim, daha başka avantajlar da var gibi gözüküyor.",
            comment_en: "Thanks to the app, I was able to rent a car safely, and it looks like there are other advantages too."
        }
    ]
    const {t,i18n} = useTranslation()
    return (
        <div className=" w-full flex justify-center items-center h-fit md:py-12 py-2 bg-[#FAE5D3]">
            <div className="container  flex justify-center items-center w-full h-full">
                <div className="flex absolute w-full xl:h-full  md:h-[calc(100vh-15rem)] justify-center items-center">
                    <div
                        className="flex flex-row snap-mandatory lg:snap-y snap-x lg:flex-col absolute gap-4 overflow-y-scroll no-scrollbar md:px-20 px-12 items-end h-fit justify-start w-full lg:w-[750px] lg:h-full space-y-12">
                        {
                            reviews.map((comments, index) => (
                                <div
                                    key={index}
                                    className="flex snap-center bg-white/60 backdrop-blur-lg shadow-lg justify-center items- md:p-[30px] lg:w-full h-fit rounded-xl">
                                    <div
                                        className="flex flex-col lg:w-full lg:h-full w-[300px] h-fit gap-4 p-8 justify-center">
                                        <img src={comments.image} className="md:w-[65px] w-[50px] border  rounded-full"
                                             alt={comments.customer}/>
                                        <p className="md:text-[16px] text-[14px] text-[#000] line-clamp-3">{i18n.language === "tr" ? comments.comment_tr : i18n.language === "tr-TR" ? comments.comment_tr : comments.comment_en}</p>
                                        <p className="md:text-[14px] text-[12px] text-[#000]">- {comments.customer}</p>
                                    </div>
                                </div>
                            ))
                        }
                        {/*<div className="md:flex hidden sticky bottom-[0px] bg-[#FF9A62] py-4 animate-bounce px-6 rounded-full bg-opacity-75 text-white">
                    <p>Dahası İçin Kaydırın</p>
                    <span>
                        <KeyboardDoubleArrowDownIcon/>
                    </span>
                </div>*/}

                    </div>
                </div>

                <div className="flex justify-center items-center w-full md:h-full h-fit">
                    <div
                        className="images grid md:grid-cols-4 grid-cols-3 md:p-4 p-2 md:gap-4 gap-1 justify-center w-fit h-fit">
                        {
                            images.map((data, index) => (
                                <img key={index} src={data.image} alt={data.alt} className="" id={index}/>
                            ))
                        }
                    </div>
                </div>
            </div>


        </div>
    )
}