import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Partners() {

    const {t,i18n} = useTranslation()
    const partners = [
        {
            company:"Slk Group Rent a Car",
            image: "/img/logos/slk_rentacar_partner.png",
            slug: "https://www.slkgrouprentacar.com/"
        },
        {
            company:"Phuket Rehberi",
            image: "/img/logos/phuket.png",
            slug: "https://www.phuketrehberi.com/phuket-turkce-rehber-rehberlik-"
        },
        {
            company:"Turkoder",
            image: "/img/logos/kbo-logo.png",
            slug: "https://www.turkoder.com/"
        },
        {
            company:"SabeeApp",
            image: "/img/logos/sabeeapp.png",
            slug: "https://www.sabeeapp.com/"
        }
    ];

    return (
        <div className=" bg-[#fff4ed] h-fit">
            <div className="flex-col text-center w-full space-y-[32px] h-fit py-[32px]">
                <h1 className="capitalize p-0 m-0 text-[21px] font-medium leading-[21px] ">{t("partners")}</h1>
                <div
                    className="flex snap-mandatory snap-x lg:justify-center justify-start overflow-x-auto no-scrollbar gap-[75px]">
                    {partners.map((logos, index) => (
                        <Link to={logos.slug} target={"_blank"} key={index} className="snap-center">
                            <img className="lg:mx-0 md:mx-4 mx-8 object-contain min-w-[150px] h-[60px]"
                                 src={logos.image} alt={logos.company}/>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}